import "../../../../../assets/external/jPlayer/js/jquery.jplayer.js";

import "./KarplayerComponent.less";

import ReadAlong from './subs_synchronized';
import playerTemplate from "./KarplayerTemplate.html";
// import * as SiriWave from 'siriwave';
// import SiriWave from 'siriwave';
import SiriWave from './wave/index';

import ConvertTime from "./ConvertTime";
import PlayerStatus from "./PlayerStatus";
import PlayerEngineJPlayer from "./PlayerEngineJplayer";
import PlayerEngineAudioTag from "./PlayerEngineAudioTag";
import {AnalyzerData, SongSource} from "./IPlayerEngine";
import PlayerEngineAudioApi from "./PlayerEngineAudioApi";
import {Component} from "../../../general/decorators";
import { IAngularStatic, IPromise } from "angular";
import {karaoke} from "../../karaoke";
import SettingsService from "../../services/SettingsService";
import {Block, Syllable} from "../../services/SubtitleService";
import PlayerEngine from "./PlayerEngine";
import Song from "../../entity/Song";
import IScope = karaoke.IScope;
import TextUtilService from "../../services/TextUtilService";
import ModalMessageService from "../../services/ModalMessageService";

class KarData {
    public title: string;
    public cache: boolean;
    public songUrl: (string) => string;
    public subData: Array<Block>;
}

class KarPlayerEvent {
    constructor(
        public type: KarPlayerEventType,
        public time: number,
        public length: number | null = null,
        public preventPlaying: boolean = false,
    ) {
    }
}

enum KarPlayerEventType {
    READY_TO_SING = 'stage-countdown-3sec'
}

const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`

@Component({
    transclude: true,
    controllerAs: '$ctrl',
    bindings: {
        data: "<",
        song: "<",
        end: "<",
        recState: "<",
        playerStatus: "<",
        notifyState: "<",
        notifyFinish: "<",
        doNext: "<",
        selectRecordMode: "<"
    },
    template: playerTemplate
})
export default class KarplayerComponent implements ng.IOnInit, ng.IOnDestroy {

    static $inject = [
        '$scope', '$element', '$timeout', '$interval', '$rootScope',
        'SettingsService', 'TextUtilService', 'ModalMessageService'
    ];

    private readonly $: JQueryStatic;
    private readonly angular: IAngularStatic;

    private _data: KarData = null;
    public song: Song = null;
    public end: boolean = false;
    public recState: string; // SongAction / 'off' / null
    public playerStatus: (PlayerStatus) => void;
    public notifyState: (string) => void;
    public notifyFinish: () => void;
    public doNext: () => void; // ng.IAngularEvent
    public selectRecordMode: () => void; // ng.IAngularEvent

    public engine: PlayerEngine = null;
    public karaoke = {
        // title: "Загрузка",
        state: "loading",
        progress: 0,
        current: "00:00",
        end: "00:00",
        duration: 0
    }
    private adjustText: boolean = true
    private $subs: JQuery = null;
    private waves: Array<SiriWave> = null;

    private redrawInterval: IPromise<any>;

    private animateStage = null;
    private eventClasses: Array<string> = [];
    private eventTimeLine: Array<KarPlayerEvent> = [];
    public rewinderLeft: string;

    constructor(
        public readonly $scope: IScope,
        public readonly $element: JQuery,
        public readonly $timeout: ng.ITimeoutService,
        public readonly $interval: ng.IIntervalService,
        public readonly $rootScope: karaoke.IRootScopeService,
        public readonly settingsService: SettingsService,
        public readonly textUtilService: TextUtilService,
        public readonly modalMessageService: ModalMessageService
    ) {
        this.$ = (window as any).jQuery || (window as any).$;
        this.angular = window.angular; // todo
        //console.info("[KP] construct");

        this.animateStage = settingsService.isCounterInPlayerEnabled() ? false : null;
        // this.eventClasses = [].concat(this.eventClasses).concat([]);
        // this.eventTimeLine = [].concat(new KarPlayerEvent(KarPlayerEventType.READY_TO_SING, 1, 3, true)).concat([]);
    }

    get data(): KarData {
        return this._data;
    }

    set data(value: KarData) {
        this._data = value;

        // console.info("[KP] data: ");
        // console.info(value);
        if (value != null) {
            // this.karaoke.title = value.title;
            this.initPlayer(value);
        }
    }

    private addEventClass(className: string) {
        this.eventClasses = [].concat(this.eventClasses).concat([className]);
    }

    private removeEventClass(className: string) {
        this.eventClasses = this.eventClasses.filter(e => e !== className);
    }

    public $onInit(): void {
        // link(scope, element, attrs) {
        // const dataAttr = attrs.data;
        // const scope = this.$scope.$parent as any;
        // const element = this.$element;
        // const dataAttr = (this.$scope as any).$ctrl.data;
        // const dataAttr = this.$scope.$ctrl.playerStatus;

        //console.info("[KP] ok");

        const source = new SongSource(
            () => this.data.songUrl("mp3"),
            () => this.data.songUrl("m4a")
        );

        // this.engine = new PlayerEngineJPlayer($, element.find('#jplayer_karaoke'),source);
        // this.engine = new PlayerEngineAudioTag(source);
        this.engine = new PlayerEngineAudioApi(source);

        /*var $controls = {
            playPause: element.find('.kar-button-main')
        };*/

        if (this.settingsService.isWaveEnabled()) {
            /*const waveContainers: JQuery<HTMLDivElement> = this.$element.find<HTMLDivElement>('.siri-container');
            this.waves = waveContainers.map(function () {
                const wave = new SiriWave({
                    container: this,
                    width: 640,
                    height: 320,
                    color: '#FFF',
                    cover: true
                });
                wave.setAmplitude(0.01);
                wave.setSpeed(0.2);
                wave.start();
                return wave;
            }).toArray();*/

            const newWaves = [];

            const buildWave: (container: HTMLElement, color: string) => SiriWave =
                (container, color) => {
                    const wave = new SiriWave({
                        container: container,
                        width: 640,
                        height: 320,
                        color: color,
                        cover: true
                    });
                    wave.setAmplitude(0.01);
                    wave.setSpeed(0.2);
                    wave.start();
                    return wave;
                };

            // if (this.$element.has('.mobile')) {
            this.$element.find('.siri-container').map(function (i, node) {
                const $node = $(node)
                const color = $node.css("color");
                if ($node.has('.mobile')) {
                    return buildWave(this, !!color ? rgb2hex(color) : '#000');
                } if ($node.has('.desktop')) {
                    return buildWave(this, !!color ? rgb2hex(color) : '#FFF');
                } else {
                    return buildWave(this, '#000');
                }
            }).each(function () {
                newWaves.push(this);
            });
            // }
            this.waves = newWaves;
        }


        /*this.$scope.$watch("data", (value: KarData) => {
        // scope.$watch("kardata", (value: KarData) => { // todo: использовать значение параметра
            console.info("[KP] data: ");
            console.info(value);
            if (value != null) {
                this.karaoke.title = value.title;
                this.initPlayer(value);
            }
        });*/

        /*scope.$watch("song", (song: Song) => { // todo: использовать значение параметра
            this.song = song;
        });*/
    }

    public $onDestroy(): void {
        //console.info("[KP] destroy");
        this.$rootScope.watchDog = null;
        // this.$rootScope.karaokeReady = null;

        if (this.redrawInterval != null/* && this.angular.isDefined(this.redrawInterval)*/) {
            this.$interval.cancel(this.redrawInterval);
            this.redrawInterval = null;
        }

        // const status = this.engine.pause();
        const status = this.engine.destroy();
        if (!!status) {
            this.playerStatus(status);
        }
    }

    /**
     * Оповещение об изменении состоянии плеера
     * @param {string} playerState
     */
    private updateState(playerState: string) {
        this.karaoke.state = playerState;
        this.notifyState(playerState);
    }

    private initPlayer(karaokePlayerData: KarData) {

        //console.info("[KP] initPlayer");
        //console.info(karaokePlayerData);

        var textElement = this.$element.find(".passage");
        // textElement.find('.karaokeBlock').remove();
        textElement.html('');

        // var $player = self.$player;
        // var $subs = thsi.$subs;

        this.engine.addOnPlay((status) => {
            // console.info("[KP] jPlayer event play");
            this.$scope.$apply(() => {
                const playerState = status.paused ? "loading" : "play";
                // const status = this.engine.status();
                this.playerStatus(status);
                // updateState(status.currentTime === 0 ? "loading" : "play");
                this.updateState(playerState);
            });
        });

        this.engine.addOnPause((status) => {
            // console.info("[KP] jPlayer event pause");
            this.$scope.$apply(() => {
                // const status = this.engine.status();
                this.playerStatus(status);
                this.updateState("pause");
            });
            if (this.waves !== null) {
                // console.info('set amplitude on pause');
                this.waves.forEach(wave => wave.setAmplitude(0.01));
            }
        });

        // todo
        /*this.engine.addOnRateChanged((status) => {

        });*/

        const convertTime = new ConvertTime();
        this.engine.addOnTimeUpdate((status) => {
            // console.info("[KP] jPlayer event timeupdate");
            this.$scope.$apply(() => {
                // const status = this.engine.status();
                this.playerStatus(status);
                this.timeUpdate(status, convertTime);
            });
        });

        // применение классов к плееру в зависимости от наступления событий eventTimeLine
        if (this.animateStage !== null) {
            this.engine.addOnTimeUpdate((status) => {
                // console.info("[KP] jPlayer event timeupdate");

                if (this.engine.status().paused === false && this.eventTimeLine.length > 0 && this.eventTimeLine[0].time <= status.currentTime) {
                    const [event, ...tail] = this.eventTimeLine;
                    this.eventTimeLine = tail;

                    const cssClass: string = event.type;
                    if (event.preventPlaying) {
                        this.engine.pause();
                    }
                    this.animateStage = true;
                    this.addEventClass(cssClass)

                    if (event.length !== null) {
                        setTimeout(() => {
                            if (event.preventPlaying) {
                                this.engine.play();
                            }
                            this.$scope.$apply(() => {
                                this.animateStage = null;
                                this.removeEventClass(cssClass);
                            });
                        }, event.length * 1000);
                    }

                }
            });
        }

        this.engine.addOnEnded(() => {
            //console.info("[KP] jPlayer event ended");
            this.$scope.$apply(() => {
                this.notifyFinish();
            });
        });

        this.engine.addOnError(() => {
            this.modalMessageService.alert(this.textUtilService.localizedText("performance.basic.error.song"), () => this.$rootScope.go("/")); // "Невозможно загрузить песню!"
        });

        this.engine.addOnLoadedData((status) => {
            this.$scope.$apply(() => {
                this.initSubs(karaokePlayerData.subData); //

                // const loaderRoot: JQuery<HTMLElement> = this.$element.find('.kar-loader');
                // this.adjust(loaderRoot, loaderRoot.find('.screen'), 32, 512);
                this.adjust(this.$element.find('.kar-loader'), '.screen', 32, 512);

                this.timeUpdate(status, convertTime);
                // timeUpdate(this.engine.status());
                this.updateState("stop");
            });

            // запускаем проигрывание
            //// console.info(">>>>>>>>>>>> play");
            //$(this).jPlayer("play");

            //console.info("!");
            //console.info(self);
            //console.info(this.$rootScope);

            // this.$rootScope.$apply(() => {
            //     this.$rootScope.karaokeReady = this.engine;
            // });

            //console.info("[KP] jPlayer loadeddata bind");

            // fix для случая когда экран сам не перерисовывается
            this.redrawInterval = this.$interval(() => {
                const status = this.engine.status();
                if (this.$subs != null && !!status && !status.paused) {
                    this.$subs.hide();
                    this.$subs.get(0).offsetHeight; // no need to store this anywhere, the reference is enough
                    this.$subs.show();
                }
            }, 250);
        });

        this.engine.init();


        this.engine.addOnAnalyzerUpdate(/** @param data {AnalyzerData} */ (data) => {
            if (this.waves !== null) {
                // console.info('set amplitude to ' + data.amplitude);
                this.waves.forEach(wave => wave.setAmplitude(data.amplitude));
            } else {
                // console.warn('no wave');
            }
        });
    }

    private initSubs(subData: Array<Block>) {

        //console.info("[KP] initSubs start");

        const textElement = this.$element.find(".passage");
        let textData = "";

        let events: Array<KarPlayerEvent> = [];

        if (subData != null && subData.length > 0) {

            const silenceGap = 3.5; // немного больше времени чтобы успеть прочитать
            const first: number = subData
                .filter((__) => __ !== null && __.data !== null)
                .flatMap((__) => __.data)
                .find((__) => __.letters !== null && __.letters.length > 0)
                .timeStart / 1000;

            if (first > silenceGap) {
                const start = first - silenceGap;
                events.push(new KarPlayerEvent(KarPlayerEventType.READY_TO_SING, start, 3, false));
            } else {
                events.push(new KarPlayerEvent(KarPlayerEventType.READY_TO_SING, 1, 3, true));
            }

            let lastWord: Syllable | null = null;
            // генерация элементов субтитров
            subData.forEach((block) => {
                if (block != null) {
                    textData += '<div class="karaokeBlock"' +
                        ' data-block_begin="' + block.timeStart/1000 + '"' +
                        ' data-block_end="' + block.timeEnd/1000 + '"' +
                        '>\n';
                    textData += '<span class="karaokeRow">\n';
                    block.data.forEach((word) => {
                        if (word != null) {

                            // добавление подоготовки к проигрышу при длительных паузах
                            if (word.letters != null && word.letters.length > 0) {
                                if (lastWord !== null) {
                                    const silenceTime = (word.timeStart - lastWord.timeEnd) / 1000;
                                    // console.info("silenceTime: " + silenceTime);
                                    if (silenceTime >= Math.max(silenceGap, 10)) { // увеличиваем промежуток с silenceGap до 10
                                        const start = word.timeStart / 1000 - silenceGap;
                                        // console.info("!!! push on " + start + ", silenceTime is " + silenceTime);
                                        events.push(new KarPlayerEvent(KarPlayerEventType.READY_TO_SING, start, 3, false));
                                    }
                                }
                                lastWord = word;
                            }

                            textData += '<span' +
                                ' data-pitch="' + word.note + '"' +
                                ' data-begin="' + word.timeStart/1000 + '"' +
                                ' data-end="' + word.timeEnd/1000 + '"' +
                                '>' + word.letters + '</span>';
                        } else {
                            textData += '\n</span>\n<br/>\n<span class="karaokeRow">\n';
                        }
                    });
                    textData += '</span>\n';
                    textData += '</div>\n';
                }
            });
            textElement.html(textData);

            // отстройка сгенерированного текста по размеру
            this.adjustSubs(textElement);

            // подсветка блоков текста, эвенты от плеера получаются внутри
            ReadAlong.init(textElement[0], this.engine);
            // ReadAlong.init(textElement[0], this.engine);

            // тесторование анализа аудиопотока ////////////////////////////////////////////////////////////////////

            // ? https://github.com/Jam3/web-audio-player

            /*try {
                const audioCtx = this.engine.audioContext;
                const audioSourceNode = this.engine.createMediaElementSource();

                //Create analyser node
                const analyserNode = audioCtx.createAnalyser();
                analyserNode.fftSize = 256;
                self.analyserBufferLength = analyserNode.frequencyBinCount;
                self.analyserNodeDataArray = new Float32Array(self.analyserBufferLength);

                //Set up audio node network
                audioSourceNode.connect(analyserNode);
                analyserNode.connect(audioCtx.destination);

                self.analyserNode = analyserNode;
            } catch (e) {
                console.warn(e);
                self.analyserNode = null;
            }*/
            ////////////////////////////////////////////////////////////////////////////////////////////////////////



            this.$subs = textElement;
            textElement.addClass("ready");

            //console.info("[KP] initSubs OK");
        } else {
            this.modalMessageService.alert(this.textUtilService.localizedText("performance.basic.error.sub"), () => this.$rootScope.go("/")); // "Невозможно загрузить субтитры!"

            //console.info("[KP] initSubs FAIL");
        }

        events.sort((a, b) => a.time - b.time);
        this.eventTimeLine = events;
    }

    /**
     * Подгонка субтитров под ширину экарна
     *
     * todo: отслеживание изменения размера экрана
     *
     * @param textElement passage блок
     */
    private adjustSubs(textElement: JQuery<HTMLElement>) {
        //console.info("[KP] adjustText start");
        if (this.adjustText) {

            // textSelector.each(function () { $(this).find(".karaokeRow")
            // this.$element.find(".passage")
            const elements: JQuery<HTMLElement> = textElement.find(".karaokeBlock .karaokeRow");
            this.adjust(textElement, elements);
        }
        //console.info("[KP] adjustText finish");
    }

    private adjust(container: JQuery<HTMLElement>, textElementsOrSelector: JQuery<HTMLElement> | string | null, fromSize: number = 14, tillSize: number = 64) {

        let resultMinFontSize = tillSize;
        let resultMaxFontSize = fromSize;

        container.css({
            'font-size': 'initial'
        });

        const realSize = function (obj, fontSize) {

            var width = 0;
            var height = 0;

            if (obj.css('visibility') === 'hidden' && obj.css('display') !== 'none') {
                // используем сам объект

                if (fontSize != null) {
                    obj.css({'font-size' : fontSize + 'px' });
                }

                width = obj.outerWidth();
                height = obj.outerHeight();

                if (fontSize != null) {
                    obj.css({'font-size' : '' });
                }


            } else {
                // придётся использовать объект-клон

                var clone = obj.clone();

                var cssData = {
                    'visibility': "hidden"
                };

                if (fontSize != null) {
                    cssData['font-size'] = fontSize + 'px';
                }

                clone.css(cssData);

                $('body').append(clone);
                width = clone.outerWidth();
                height = clone.outerHeight();
                clone.remove();
            }

            return {
                width: width,
                height: height
            };
        };

        const optimalFontSize = function (ourText, maxWidth, maxHeight) {
            var minFontSize = fromSize;
            var maxFontSize = tillSize;

            var fontSize = Math.floor((maxFontSize + minFontSize) / 2);

            do {
                var size = realSize(ourText, fontSize);
                var match = size.height <= maxHeight && size.width <= maxWidth;

                //// console.info(match ? 'match' : 'not match');

                if (match) {
                    minFontSize = fontSize;
                } else {
                    maxFontSize = fontSize;
                }

                fontSize = Math.floor((maxFontSize + minFontSize) / 2);

                //// console.info('['+ minFontSize + "|" + fontSize + "|" + maxFontSize + ']');

            } while (fontSize > minFontSize);

            return fontSize;
        };

        /* var 1
         blocks.each(function () {

         if (!this.adjustText) {
         // если необходимо закончить подгонку текста - выходим
         return false;
         }

         var minFontSize = fromSize;
         var maxFontSize = tillSize;

         var ourText = $(this);
         var maxWidth = textElement.outerWidth();
         var maxHeight = ourText.outerHeight(); //?

         var rows = $(this).find(".karaokeRow");

         var fontSize = Math.floor((maxFontSize + minFontSize) / 2);
         ////console.info('['+ minFontSize + "|" + fontSize + "|" + maxFontSize + ']');
         do {

         // todo: хранить самую длинную строку и не обрабатывать всё что меньше её

         var totalSize = { width: 0, height: 0};
         rows.each(function () {
         var size = realSize($(this), fontSize);
         totalSize.height = totalSize.height + size.height;
         totalSize.width = Math.max(totalSize.width, size.width);
         });

         var match = totalSize.height <= maxHeight && totalSize.width <= maxWidth;

         ////console.info(match ? 'match' : 'not match');

         if (match) {
         minFontSize = fontSize;
         } else {
         maxFontSize = fontSize;
         }

         fontSize = Math.floor((maxFontSize + minFontSize) / 2);

         ////console.info('['+ minFontSize + "|" + fontSize + "|" + maxFontSize + ']');

         } while (fontSize > minFontSize);

         ////console.info(totalSize.height + "/" + maxHeight + "  " + totalSize.width + "/" + maxWidth + " > " + fontSize);

         ourText.css({
         'font-size': fontSize + 'px'
         });
         resultMinFontSize = Math.min(resultMinFontSize, fontSize);
         resultMaxFontSize = Math.max(resultMaxFontSize, fontSize);
         });*/

        let largestRow = null;
        const calcRow = function (ref) {
            return {
                ref: ref,
                size: {
                    width: ref.outerWidth(),
                    height: ref.outerHeight()
                },
                smallerThen: function (otherRef) {
                    //// console.info(this.size.width + ' ~ ' + otherRef.width())
                    return this.size.width < otherRef.outerWidth();
                }
            };
        };


        /*container.find('.karaokeBlock').each(function () {
            $(this).find(".karaokeRow").each(function () {
                if (largestRow == null) {
                    largestRow = calcRow($(this));
                } else {
                    if (largestRow.smallerThen($(this))) {
                        largestRow = calcRow($(this));
                    }
                }
            });
        });*/

        let textElements: JQuery<HTMLElement> = null;
        if (textElementsOrSelector === null) {
            textElements = null;
        } if (typeof textElementsOrSelector === "string") {
            textElements = container.find(textElementsOrSelector);
        } else if ((textElementsOrSelector as JQuery<HTMLElement>).children !== undefined) {
            textElements = textElementsOrSelector as JQuery<HTMLElement>;
        } else {
            throw new Error("Unknown textElementsOrSelector: " + JSON.stringify(textElementsOrSelector));
        }

        if (textElements !== null) {
            textElements.each(function (index: number, element: HTMLElement) {
                if (largestRow == null) {
                    largestRow = calcRow($(element));
                } else {
                    if (largestRow.smallerThen($(element))) {
                        largestRow = calcRow($(element));
                    }
                }
            });
        }

        if (largestRow === null) {
            console.warn("no largestRow was found");
        }
        
        resultMinFontSize = optimalFontSize(largestRow.ref, container.outerWidth(), container.outerHeight() / 2);

        // console.info("[KP] adjustText set size " + resultMinFontSize);

        // выставляем единый размер шрифта по просьбе Саши Коровнна
        container.css({
            'font-size': resultMinFontSize + 'px'
        });
        /*
         blocks.each(function () {
         $(this).css({
         'font-size': resultMinFontSize + 'px'
         });
         });
         */
    }

    /**
     * @param jPlayerStatus {PlayerStatus}
     * @param convertTime {ConvertTime}
     */
    private timeUpdate(jPlayerStatus: PlayerStatus, convertTime: ConvertTime) {
        // console.info("[KP] timeUpdate start");
        // console.info(jPlayerStatus);

        this.karaoke.progress = jPlayerStatus.progress;
        this.karaoke.duration = jPlayerStatus.duration;
        this.karaoke.current = convertTime.time(jPlayerStatus.currentTime);
        this.karaoke.end = convertTime.time(jPlayerStatus.duration);

        if (jPlayerStatus.currentTime > 0 && (jPlayerStatus.paused === false)) {

            // мы действительно начали играть, начальная буферизация закончилась
            if (this.karaoke.state === "loading") {
                console.info(">>>>>>>>>>>> " + this.karaoke.state);
                this.updateState("play");
            }

            if (this.$rootScope.watchDog != null && this.$rootScope.watchDog.stop === false) {
                this.$rootScope.$apply(() => {
                    this.$rootScope.watchDog.stop = true; // начали играть сами
                    //console.info("watchDog: stop by karaoke");
                });
            }
        }

        /*if (!!self.analyserNode && (Math.floor(jPlayerStatus.currentTime) % 2) === 1) {
            self.analyserNode.getFloatFrequencyData(self.analyserNodeDataArray);

            // var freqs = audioUtil.frequencies()
            var freqs = self.analyserNodeDataArray;
            var minHz = 40;
            var maxHz = 100;
            // var avg = average(analyser, freqs, minHz, maxHz);
            // var radius = Math.min(width, height) / 4 * avg

            let avg = 0;
            for (var i = 0; i < self.analyserBufferLength; i++) {
                avg += self.analyserNodeDataArray[i] / 128.0;
            }
            avg = avg / self.analyserBufferLength;

            let wv = Math.max(0, Math.min(1, Math.abs(avg)));
            let wv2 = avg < -1000 ? 0.01 : Math.max(0.1, (wv - 0.5) * 3);
            //console.info(`${jPlayerStatus.currentTime} - ${self.link.scope.karaoke.current} - ${avg} - ${wv} - ${wv2}`);

            self.wave.setAmplitude(wv2);
        }*/

        ////console.info("[KP] timeUpdate finish");
    }

    public playPause(): void {
        // console.info("[KP] playPause click");

        // const self = !!this.playPause ? this : KarplayerComponent.prototype; // hack: в this оказывается PlayerButtonComponent.ts

        // console.info("event: ");
        // console.info(e);
        // console.info(this.karaoke.state);
        // console.info(this);
        // console.info(KarplayerComponent.prototype);

        if (this.animateStage === true) {
            // во время анимации клики не обрабатываем
            return;
        }

        const self = this;
        switch (self.karaoke.state) {
            case "stop":
            case "pause":
                // console.info("[KP] do play");
                /*if (e.originalEvent === undefined) console.info('triggered by code'); else console.info('triggered by mouse');*/

                self.karaoke.state = "play";
                self.engine.play();
                break;

            case "play":
                self.karaoke.state = "pause";
                self.engine.pause();
                break;
        }
        // return false;
    };

    public rewinderable() {
        return this.recState === 'off';
    }

    public showRewinder(mouseEvent: MouseEvent = null) {
        if (this.rewinderable()) {
            if (!mouseEvent) {
                this.rewinderLeft = -10 + 'px';
            } else {
                const parentArray = this.$(mouseEvent.target).parents('.kar-progress');
                if (parentArray.length > 0) {
                    const parent: JQuery<HTMLElement> = this.$(parentArray[0]) as JQuery<HTMLElement>;
                    const posX = mouseEvent.pageX - $(parent).offset().left;
                    this.rewinderLeft = posX + 'px';
                }
            }

        }
    }

    public clickRewinder(clickEvent: MouseEvent = null) {
        if (this.rewinderable()) {
            const parentArray = this.$(clickEvent.target).parents('.kar-progress');
            if (parentArray.length > 0) {
                const parent: JQuery<HTMLElement> = this.$(parentArray[0]) as JQuery<HTMLElement>;
                const width = parent.width();
                const posX = clickEvent.pageX - $(parent).offset().left;
                const newTime = this.karaoke.duration / width * posX;
                this.engine.setCurrentTime(Math.max(0, newTime));
            }
        }

        clickEvent.stopPropagation();
        clickEvent.preventDefault();
        return false;
        // clickEvent.offsetX
    }
}