import TextUtilService from "../services/TextUtilService";
import BrandingService from "../../../branding/BrandingService";
import StorageService from "../services/StorageService";
import AuthService from "../services/AuthService";
import {karaoke} from "../karaoke";
import IScope = karaoke.IScope;
import { IHttpHeadersGetter, IRequestConfig } from "angular";

export default class BasicAuthController {

    static $inject = [
        '$scope', 'TextUtilService', 'BrandingService', 'StorageService', 'AuthService'
    ];

    constructor(
        protected readonly $scope,
        protected readonly textUtilService: TextUtilService,
        protected readonly brandingService: BrandingService,
        protected readonly storageService: StorageService,
        protected readonly authService: AuthService,
    ) {

        $scope.doBasicAuth = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            const login = this.getLogin();
            const password = this.getPassword();
            if (!!login && !!password) {
                this.auth(login, password);
            } else {
                // 'Пожалуйста, введите номер телефона и пароль'
                const msg = this.textUtilService.localizedText("auth.required-fields");
                // doAuth(authForm, msg, authData.login, authData.password);
                this.onError(msg);
            }
        }

        $scope.doBasicAuthClose = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            this.cancelAction();
        }
    }

    protected auth(login: string, password: string): void {
        this.authService.basicAuth(login, password,
            this.postAction,
            (data: string, status: number, headers: IHttpHeadersGetter, config: IRequestConfig, msg: string) => {
                this.onError(this.authService.authMessage(data, status, headers, config, msg));
            });
    }

    protected postAction = () => {
        let pa = this.$scope.postAction
        if (typeof pa === 'function') {
            pa();
        }
        this.close();
    }

    protected cancelAction = () => {
        let ca = this.$scope.cancelAction
        if (typeof ca === 'function') {
            ca();
        }
        this.close();
    }

    public close = () => this.$scope.$emit("close");
    public getLogin = () => this.$scope.login;
    public getPassword = () => this.$scope.password;
    public onError = (errorMessage: string) => this.$scope.basicAuthErrorMessage = errorMessage;
}