import {BasicAuthResult} from "../services/entity/BasicAuthResult";
import BasicAuthController from "./BasicAuthController";
import DataService from "../services/DataService";
import TextUtilService from "../services/TextUtilService";
import BrandingService from "../../../branding/BrandingService";
import StorageService from "../services/StorageService";
import AuthService from "../services/AuthService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;

export class CommonAuthProfile {
    opNetwork: boolean
    msisdn: string
}

export enum CommonResponseStatus {
    OK = 'OK',
    ERROR = 'ERROR'
}

export class CommonOrder {
    msisdn: string
    orderId: string
    originOrderId: string
    createdAt: Date
    price: number
}

export class CommonOpenResponse {
    status: CommonResponseStatus
    order: CommonOrder
    message: string
}

export class CommonWebhookData {
    orderId: string
    originOrderId: string
    startedDatetime: Date
    finishedDatetime: Date
    serviceToken: string
    serviceName: string
    price: number
    phone: string
}

export class CommonWebhookSubscriptionData {
    webhook: CommonWebhookData
    result: BasicAuthResult
    authToken: string
}

export enum CommonLandingStage {
    AUTH = 0,
    ENTER_CODE = 1,
    AWAIT_RESULT = 2,
    SUBSCRIBED = 3,
    ERROR = 4
}

export class CommonPasswordResponse {
    /**
     * Статус запроса
     * “ok” или “error”
     */
    status: CommonResponseStatus;

    /**
     * Количество попыток переотправки пароля для данного заказа.
     */
    attemptsLeft: number

    /**
     * Текст ошибки в случае если status = “error”.
     * Если status = “ok”, данное поле будет содержать пустую строку
     */
    message: string
}

export default class BasicSubAuthController extends BasicAuthController {

    private active = true;

    constructor(
        protected readonly urlPrefix: string,
        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly $controller: ng.IControllerService,
        protected readonly dataService: DataService,
        protected readonly textUtilService: TextUtilService,
        protected readonly brandingService: BrandingService,
        protected readonly storageService: StorageService,
        protected readonly authService: AuthService,
    ) {
        super($scope, textUtilService, brandingService, storageService, authService)

        $scope.stage = CommonLandingStage
        $scope.error = false
        $scope.loading = false;
        $scope.landingMode = false

        $scope.doAuth = () => $scope.landingMode = false
        $scope.doAuthAfterSub = () => this.doAuthAfterSub()
        $scope.doCheck = () => this.doCheckSubscriptionStatus()
        $scope.doBegin = () => this.doBegin();
        $scope.doOpen = () => this.doOpen()
        $scope.doValidate = () => this.doValidate()
        $scope.doResend = () => this.doResend()
        $scope.doSubscribeOnWebhookResult = (orderId: string, response: CommonPasswordResponse) => this.doSubscribeOnWebhookResult(orderId, response)
        $scope.doHandleSuccessResult = (data: CommonWebhookSubscriptionData) => this.doHandleSuccessResult(data)
        $scope.$on('$destroy', () => this.active = false);

        /*this.$scope.login = authService.userData.msisdn;
        this.$scope.subscribeMode = authService.userData.authenticated && !authService.isSubscribedMain();
        this.$scope.checkboxAccepted = false;
        this.$scope.doSubscribe = this.doSubscribe;
        this.$scope.showTerms = this.$rootScope.showTerms;
        this.$scope.doCancelSubscribeMode = ($event) => {
            $event.stopPropagation()
            this.$scope.subscribeMode = false;
        }*/

        this.doBegin();
    }

    protected doSubscribe = ($event) => {
        $event.stopPropagation();

        let msisdn = this.authService.userData.msisdn;
        if (!!msisdn) {
            this.$scope.loading = true;
            let clearMsisdn = msisdn.replace(/ /g,'').replace(/\+/g,'')
            this.dataService.dataHttp<BasicAuthResult>(`${this.urlPrefix}/subscribe.json`, {
                params: {
                    'phone': clearMsisdn
                }
            }, 'POST').then(response => {
                // const alreadySubscribed = response.data == BasicAuthResult.OK;
                this.$scope.loading = false;
                if (this.isOk(response.data)) {
                    this.$scope.error = false;

                    this.authService.reloadPage();
                    // this.authService.submitActionDefault(new SubMainContext());
                } else {
                    // `Некорректный ответ: ${response.data}`
                    let message = this.textUtilService.localizedText("auth.error.wrong-answer", {response: response.data, interpolation: {escapeValue: false}})
                    this.handleError(message)
                }
            }, () => {
                this.handleError(this.textUtilService.localizedText("payment.error.general", {}, "Невозможно осуществить подписку"))
            });
        } else {
            this.handleError(this.textUtilService.localizedText("auth.required-msisdn", {}, "Введите номер телефона"))
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    protected doBegin = () => {
        this.$scope.msisdn = null;
        this.$scope.landingStage = CommonLandingStage.AUTH;
        this.doLoadProfile();
    }

    protected doLoadProfile = () => {
        this.$scope.loading = true;
        this.dataService.dataHttp<CommonAuthProfile>(`${this.urlPrefix}/landing/profile.json`).then(response => {
            this.$scope.landingMode = true; // response.data.opNetwork;
            if (!this.$scope.msisdn) {
                this.$scope.msisdn = response.data.msisdn;

                // if (this.$rootScope.mode === 'landing') { // $rootScope
                //     // UZTelecomAuthController case
                //     // определили номер телефона и находимся на лендинге – автоматически отправим SMS-код
                //     this.doOpen()
                // } else {
                //     this.$scope.landingStage = CommonLandingStage.AUTH;
                // }

                this.$scope.landingStage = CommonLandingStage.AUTH;
            }
            this.handleSuccess()
        }, () => {
            // Невозможно загрузить подписку
            this.handleError(this.textUtilService.localizedText("auth.error.unable-to-load-sub"))
        });
    }

    protected doCheckSubscriptionStatus = () => {
        const msisdn = this.$scope.msisdn;

        console.info("doCheckSubscriptionStatus");

        if (!!msisdn) {
            this.$scope.loading = true;
            let clearMsisdn = msisdn.replace(/ /g,'').replace(/\+/g,'')
            this.dataService.dataHttp<BasicAuthResult>(`${this.urlPrefix}/subscription-status.json`, {
                params: {
                    'phone': clearMsisdn
                }
            }, 'POST').then(response => {
                const alreadySubscribed = response.data == BasicAuthResult.OK;
                if (this.isOk(response.data)) {
                    this.$scope.landingMode = false;
                    this.$scope.login = clearMsisdn;
                    this.handleSuccess();
                } else {
                    this.doOpen()
                }
            }, () => {
                this.handleErrorGlobal(this.textUtilService.localizedText("payment.error.general", {}, "Невозможно осуществить подписку"))
            });
        } else {
            this.handleError(this.textUtilService.localizedText("auth.required-msisdn", {}, "Введите номер телефона"))
        }
    }

    protected doOpen = () => {
        const msisdn = this.$scope.msisdn;
        if (!msisdn) {
            this.handleError(this.textUtilService.localizedText("auth.required-msisdn", {}, "Введите номер телефона"))
            return
        }
        this.$scope.loading = true;
        let clearMsisdn = msisdn.replace(/ /g,'').replace(/\+/g,'')
        this.dataService.dataHttp<CommonOpenResponse>(`${this.urlPrefix}/landing/open.json`, {
            params: {
                'phone': clearMsisdn
            }
        }, 'POST').then(response => {
            if (response.data.status == CommonResponseStatus.OK) {
                console.info(response.data);
                console.info(response.data.order);
                this.$scope.order = response.data.order;
                this.$scope.landingStage = CommonLandingStage.ENTER_CODE;
                this.handleSuccess()
            } else if (response.data.status == CommonResponseStatus.ERROR) {
                this.handleError(response.data.message)
            } else {
                console.warn(`wrong answer from uztelecom/landing/open: ${JSON.stringify(response.data)}`);
                // некорректный ответ от сервера
                this.handleError(this.textUtilService.localizedText("auth.error.wrong-server-response"))
            }
        }, () => {
            this.handleError(this.textUtilService.localizedText("payment.error.general", {}, "Невозможно осуществить подписку"))
        });
    }

    protected doValidate = () => {
        const orderId = this.orderId();
        const password = this.$scope.code;
        if (!password) {
            // Введите пароль
            this.handleError(this.textUtilService.localizedText("auth.label-enter-password"))
            return
        }
        if (!!orderId) {
            this.$scope.loading = true;
            this.dataService.dataHttp<CommonPasswordResponse>( `${this.urlPrefix}/landing/validate-password.json`, {
                params: {
                    'orderId': orderId,
                    'password': password
                }
            }, 'POST').then(response => {
                if (response.data.status == CommonResponseStatus.OK) {
                    this.$scope.landingStage = CommonLandingStage.ENTER_CODE;
                    this.$scope.attemptsLeft = response.data.attemptsLeft;
                    this.handleSuccess()

                    // const orderId = (this.$scope.order as CommonOrder)?.orderId;
                    this.$scope.doSubscribeOnWebhookResult(orderId, response.data)
                } else if (response.data.status == CommonResponseStatus.ERROR) {
                    this.handleError(response.data.message)
                } else {
                    console.warn(`wrong answer from uztelecom/landing/validate-password: ${JSON.stringify(response.data)}`);
                    // некорректный ответ от сервера
                    this.handleError(this.textUtilService.localizedText("auth.error.wrong-server-response"))
                }
            }, () => {
                // Невозможно проверить пароль
                this.handleError(this.textUtilService.localizedText("auth.error.unable-to-verify-password"))
            });
        } else {
            this.errorReset()
        }
    }

    protected doResend = () => {
        const orderId = this.orderId();
        if (!!orderId) {
            this.dataService.dataHttp<CommonPasswordResponse>(`${this.urlPrefix}/landing/resend-password.json`, {
                params: {
                    'orderId': orderId,
                    'lang': this.textUtilService.currentLanguage,
                }
            }, 'POST').then(response => {
                if (response.data.status == CommonResponseStatus.OK) {
                    this.$scope.landingStage = CommonLandingStage.ENTER_CODE;
                    this.$scope.attemptsLeft = response.data.attemptsLeft;
                    this.handleSuccess()
                } else if (response.data.status == CommonResponseStatus.ERROR) {
                    this.handleError(response.data.message)
                } else {
                    console.warn(`wrong answer from /landing/resend-password: ${JSON.stringify(response.data)}`);
                    // некорректный ответ от сервера
                    this.handleError(this.textUtilService.localizedText("auth.error.wrong-server-response"))
                }
            }, () => {
                // Невозможно повторно запросить пароль
                this.handleError(this.textUtilService.localizedText("auth.error.unable-to-request-password"))
            });
        } else {
            this.errorReset()
        }
    }

    protected doSubscribeOnWebhookResult = (orderId: string, response: CommonPasswordResponse) => {
        this.$scope.landingStage = CommonLandingStage.AWAIT_RESULT;
        this.subscribe(orderId, 10)
    }

    protected doHandleSuccessResult = (data: CommonWebhookSubscriptionData) => {
        console.info(data.result);
        if (this.isOk(data.result)) {
            this.$scope.landingStage = CommonLandingStage.SUBSCRIBED;
            this.$scope.subData = data;
            // show button with action doAuthAfterSub on state SUBSCRIBED
        } else {
            // 'Подписка не оформлена: ' + data.result
            this.handleErrorGlobal(this.textUtilService.localizedText("auth.error.subscription-failed", {response: data.result, interpolation: {escapeValue: false}}))
        }
    }

    protected doAuthAfterSub = () => {
        const data = this.$scope.subData;
        if (!!data.authToken) {
            console.info("got authToken");
            const login = data.webhook.phone;
            const password = data.authToken;
            this.auth(login, password); // todo
            // this.authService.reloadPage();
        } else {
            this.handleSuccess()
        }
    }

    protected handleSuccess() {
        this.$scope.error = false;
        this.$scope.loading = false;
    }

    protected handleError(message: string) {
        this.$scope.attemptsLeft = null;

        this.$scope.error = true;
        this.$scope.errorMessage = message;
        this.$scope.loading = false;
    }

    protected handleErrorGlobal(message: string) {
        this.$scope.landingStage = CommonLandingStage.ERROR;
        this.handleError(message);
    }

    protected orderId() {
        return (this.$scope.order as CommonOrder)?.orderId;
    }

    protected errorReset() {
        this.$scope.landingStage = CommonLandingStage.AUTH;
        this.$scope.order = null
        this.$scope.attemptsLeft = null
        // Некорректный идентификатор запроса
        this.handleError(this.textUtilService.localizedText("auth.error.wrong-request-id"))
    }

    protected subscribe(orderId: string, retryLimit: number) {
        if (!this.isActive()) {
            return;
        }

        if (retryLimit < 0) {
            // Превышено число попыток. Попробуйте позже.
            this.handleErrorGlobal(this.textUtilService.localizedText("auth.error.attempts-exceed"))
            return;
        }

        const self = this;
        if (!!orderId) {
            self.dataService.dataHttp<CommonWebhookSubscriptionData>( `${this.urlPrefix}/landing/status.json`, {
                params: {
                    'orderId': orderId,
                }
            }, 'POST').then(response => {
                // новые данные
                try {
                    console.info(response.data);
                    if (!!response.data.webhook.finishedDatetime) {
                        self.$scope.doHandleSuccessResult(response.data)
                    } else {
                        setTimeout(new function () {
                            self.subscribe(orderId, --retryLimit);
                        }, 1000); // попробовать ещё раз через 1 сек
                    }
                } catch (e) {
                    console.error(e);
                    setTimeout(new function () {
                        self.subscribe(orderId, --retryLimit);
                    }, 1000); // попробовать ещё раз через 1 сек
                }
            }, errorResponse => {
                switch (errorResponse.status) {
                    case 404:
                    case 405:
                        // Запрос на подписку не найден
                        this.handleErrorGlobal(this.textUtilService.localizedText("auth.error.wrong-order-id"))
                        break
                    case 502:
                    case 503:
                        console.info("retry on " + errorResponse.status + " " + errorResponse.statusText);
                        setTimeout(new function () {
                            self.subscribe(orderId, --retryLimit);
                        }, 1000); // попробовать ещё раз через 1 сек
                        break;
                    default: // console.warn(`subscribe on webhook returns code ${errorResponse.status}`);
                        // this.handleError('Невозможно получить статус подписки')
                        console.warn(`subscribe on webhook returns code ${errorResponse.status}`);
                        setTimeout(new function () {
                            self.subscribe(orderId, --retryLimit);
                        }, 1000); // попробовать ещё раз через 1 се
                        break;
                }
            })
        } else {
            this.errorReset()
        }
    }

    protected isOk(value: BasicAuthResult) : boolean {
        return value == BasicAuthResult.OK || value as any == "OK"// todo: custom desirializer
    }

    protected isActive() {
        return this.active;
    }
}