//import './core-tele2';

import karaoke from '../../application/app-karaoke';
import brandingRunner from './app-tele2';
brandingRunner(karaoke);

/*import('../../application/karaoke').then(karaoke => {
    import('./app-tele2').then(brandingRunner => {
        console.info("test karaoke-tele2 2");
        brandingRunner(karaoke);
    });
});*/
